.message-item {
    padding: 5px 0px;
    position: relative;
    overflow: hidden;
}
.message-item .message-item-content-wrapper {
    max-width: 85%;
}
.message-item .message-item-content {
    position: relative;
    display: inline-block;
    padding: 10px;
    border-radius: 3px;
    line-height: 24px;
    word-break: break-all;
    min-height: 36px;
    margin: 0px;
    box-sizing: border-box;
}

.message-item .message-item-content:before {
    content: '';
    display: block;
    position: absolute;
    top: 22px;
    width: 0;
    height: 0;
}

.message-item .message-item-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
}

.message-item-other {
    padding-left: 50px;
}

.message-item-other .message-item-avatar {
    left: 5px;
}

.message-item-other .message-item-content-wrapper {
    float: left;
    position: relative;
}

.message-item-other .message-item-content {
    /* float: left; */
    background-color: rgb(252, 246, 229);
    color: black;
    /* position: relative; */
}
.message-item-other .message-item-content:before {
    left: -10px;
    border-left: 12px solid transparent;
    border-bottom: 12px solid rgb(252, 246, 229);
}

.message-item-other .message-item-time {
    color: gray;
    font-size: 90%;
}

.message-item-self {
    padding-right: 50px;
}

.message-item-self .message-item-avatar {
    right: 5px;
}
.message-item-self .message-item-content-wrapper {
    float: right;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.message-item-self .message-item-content {
    float: right;
    background-color: var(--button-color);
    color: white;
    align-self: flex-end;
}

.message-item-self .message-item-content:before {
    right: -10px;
    border-right: 12px solid transparent;
    border-bottom: 12px solid var(--button-color);
}

.message-item-self .message-item-time {
    float: right;
    color: gray;
    font-size: 90%;
    align-self: flex-end;
    /* position: relative; */
}