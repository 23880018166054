.talent-action-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;

    margin-top: 10px;

    min-height: 90px;
    flex-flow: wrap; /* 需要时换行，默认为nowrap */
    align-content: space-around;
}
.talent-action-toolbar .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.talent-action-toolbar .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
}