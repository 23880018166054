.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.7); 
  opacity: 0;
  /* transition: all 0.2s ease-in-out; */
  pointer-events: none;
}

.modal.open {
  opacity: 1;
  pointer-events: visible;
}

.close {
  /* color: #aaa; */
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -10px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  background-color: white;
}

.modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--page-color);
  margin: 100px auto;
  padding: 15px 15px;
  border: 1px solid #888;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  /* transform: translateY(-200px); */
  /* transition: all 0.2s ease-in-out; */
  color: black;
}

.modal.open .modal-content {
  transform: translateY(0);
}

.modal.open {
  transform: translateX(0);
}

@media (max-width: 600px) {
  .modal-content {
    max-width: 300px;
    margin: 70px auto;
  }
}

.modal-header {
  font-size: 1.1em;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
}

.modal-body {
  margin-top: 15px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

/* The Confirm Button */
.ok-button {
  width: 100px;
}

.ok-button:disabled {
  cursor: not-allowed;
  background-color: gray;
}

/* The Cancel Button */
.cancel-button {
  background-color: var(--body-color);
  width: 85px;
  margin-right: 10px;
}

.modal-close-button {
  position: absolute; 
  right: 0;
  top: 0;
  background-color: transparent;
  width: 45px;
  height: 45px;
  font-size: 18pt;
  padding: 8px;
  /* color: #ffffff80; */
  min-width: 45px;
  color: black;
}
