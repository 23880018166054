.case-card {
    background-color: var(--section-color);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
}

.case-card .case-card-main {
    display: flex;
    flex-direction: column;
}

.case-card .case-card-main .case {
    display: flex;
    flex-direction: column;
}

.case-card .case-card-main .case .text-container.opened {
    /* color: white; */
}

.case-card .case-card-main .case .text-container.closed {
    color: grey;
}

.case-card .case-card-main .case .edit-container .case-post-btns {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 10px;
}