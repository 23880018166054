.main {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  padding: 20px 10px;
  row-gap: 10px;

  font-size: 90%;
}

.case-post-container {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.case-post-container .case-post-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}