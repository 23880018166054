.main {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px 10px;
    row-gap: 10px;
}

.box-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
    align-items: center;

    column-gap: 10px;
    row-gap: 10px;
}

.topic-box {
    /* width: 50%; */
}

.line {
    border: none;
    height: 1px;
    background-color: lightgray;
    width: 100%;
}
input[type="text"] {
    height: auto;
    min-height: auto;
}
@media (min-width: 1068px) {
    .topic-box {
        width: 30%;
    }
}

@media only screen and (max-width: 1068px) {
    .topic-box {
        width: 30%;
    }
}

@media only screen and (max-width: 850px) {
    .topic-box {
        width: 40%;
    }
}

@media only screen and (max-width: 710px) {
    .topic-box {
        width: 40%;
    }
}

@media only screen and (max-width: 430px) {
    .topic-box {
        width: 100%;
    }
}