.matched-case-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    border-style: dashed;
    border-radius: 10px;
    border: 1px dashed gray;

    padding: 10px;
    margin-top: 10px;
}

.matched-case-container .title {
    color: gray;
    font-weight: bold;
}

.matched-case-container .case-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;

    color: var(--link-color);
    cursor: pointer;
}

.matched-case-container .case-item.closed {
    color: grey;
}
.matched-case-container .case-item.unlock::before {
    color: burlywood;
    content: '\1F513';
}
.matched-case-container .case-item.lock::before {
    color: burlywood;
    content: '\1F512';
}

.matched-case-container .case-item .status {
    overflow: hidden;
}

.matched-case-container .case-item .brief {
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;

    flex: 1;
}