.unmatched-list-title {
    display: flex;
    flex-direction: row;

    font-weight: bold;
}

.unmatched-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    padding-top: 5px;
    padding-bottom: 5px;

    border-bottom: 1px solid lightgrey;
}

.unmatched-list-item:last-child {
    border-bottom: none;
}

.unmatched-list-item .title {
    width: 70%;
    text-align: justify;
}

.unmatched-list-item .button {
    width: auto;
}