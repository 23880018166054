.main {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px 10px;
    row-gap: 10px;
}

.talent-info {
    /* color: white; */
}

.matched-case-info .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 10px 0px 0px 0px;
    background-color: var(--section-header-color);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 8px;
    color: white;
}

.matched-case-info .content {
    font-size: 100%;
    margin: 0px 0px 0px 0px;
    background-color: var(--section-color);
    padding: 10px;
}

.matched-case-info .toolbar {

    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    

    margin: 0px 0px 0px 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--section-color);
    padding: 10px;

    min-height: 90px;
    flex-flow: wrap; /* 需要时换行，默认为nowrap */
    align-content: space-around
}
.matched-case-info .toolbar .left, .matched-case-info .toolbar .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
}
/* .matched-case-info .toolbar button {
    font-size: 80%;
    min-width: 40px;
} */

.matched-case-info .warning {
    background: #fffbe6;
    border: 1px solid #ffe58f;
    border-radius: 8px;
    padding: 20px 24px;
    margin-top: 10px;
    
    display: flex;
    justify-content: center;
}