.chat-container .title {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 3px;

    margin: 10px 0px 0px 0px;
    background-color: var(--section-header-color);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    color: white;
}
.chat-container .title .primary {
    font-size: inherit;
}
.chat-container .title .second {
    font-size: 80%;
}

.chat-container .content {
    font-size: 100%;
    margin: 0px 0px 0px 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--section-color);
    column-gap: 10px;
    padding: 10px;
    color: white;
}

.chat-container .content .chat {
    display: flex;
    flex-direction: column;
}

.message-input-area {
    width: 80%;
    margin-right: 20px;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.message-input-area input {
    flex:1
}

@media (max-width: 620px) {
    .message-input-area {
        width: 66%;
    }
}