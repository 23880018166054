.modal-content {
  background-color: var(--page-color);
  margin: 100px auto;
  /* padding: 7px 10px 0px 10px; */
  border: 1px solid #888;
  border-radius: 20px;
  /* width: 500px; */
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  position: relative;
  /* color: #ffffff80; */
  color: black;
}

/* 登录容器 */
.login-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 2px 2px;
}

/* 登录方式切换容器 */
.login-box-tabs {
  width: 100%;
  padding: 32px 24px 0;
  box-sizing: border-box;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  clear: both;
}

/* 登录方式切换标题容器 */
.login-box-tabs-titles {
  font-weight: 600;
  height: 32px;
  box-sizing: content-box;
  margin-bottom: 16px;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: center;
  position: relative;
  color: black;
}

/* 登录方式切换标题 */
.login-box-tabs-titles span  {
  width: 120px;
  display: inline-block;
  font-size: 16px;
  height: 22px;
  line-height: 16px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  color: gray;
}

/* 登录方式切换标题 */
.login-box-tabs-titles span.active  {
  color: black;
}
.login-box-tabs-titles span.active::after  {
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  background: gray;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.hide{display: none;}

.show{display: block;}

/* 微信公众号登录Tab容器 */
.login-wechat-mp {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
}

/* 扫码关注公众号提示 */
.login-wechat-mp-tips {
  font-size: 16px;
}

/* 公众号二维码区域 */
.login-wechat-mp-code {
  width: 180px;
  height: 180px;
  margin: 0 auto;
}

/* 公众号二维码 */
.login-wechat-mp-code .qrcode {
  width: 180px;
  height: 180px;
  min-width: 180px;
  min-height: 180px;
}

/* 公众号二维码失效区域 */
.login-wechat-mp-code.expired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  cursor: pointer;
}

.login-wechat-mp-code.expired .mark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  /* font-size: 14px; */
  /* color: #fff; */
  box-sizing: border-box;
  line-height: 1.5;
}

.login-wechat-mp-code.expired .retry-icon {
  /* text-align: center; */
  z-index: 10;
}

.login-wechat-mp-code.expired .retry-name {
  /* text-align: center; */
  font-size: 15px;
  z-index: 10;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* 账号密码登录 */
.login-form {
  box-sizing: border-box;
}
.login-form-item {
  margin-top: 10px;
}

.base-input-text {
  /* width: 100%; */
  height: 40px;
  line-height: 40px;
  background: #f5f6f7;
  border-radius: 4px 4px;
  padding: 0 16px;
  color: #222226;
  position: relative;
  clear: both;
}

.bottom-group {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.forget-password {
  font-size: 16px;
}