.main {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px 10px;
    row-gap: 10px;
}
.profile-page-header {
    
}

.profile-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.profile-item {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    column-gap: 10px;
}

.profile-item .profile-item-title {

}

.profile-item .profile-item-content {

}

.profile-item .profile-item-btns {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    column-gap: 10px;
}

.btn-link {
    color: var(--link-color);
    cursor: pointer;
}

.profile-item .profile-item-content.name {
    display: flex;
    flex-direction: row;

    column-gap: 20px;
}

.lastName, .firstName {
    display: flex;
    flex-direction: row;
}

.invite-code {
    font-weight: bold;
    /* color: var(--link-color); */
    color: green;
}