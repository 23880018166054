.main {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px 10px;
    row-gap: 10px;
}
.feedback-page-header {
    
}

.feedback-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page-form {

}

.page-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin-bottom: 20px;
}

.page-form-item-label {
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
}

.required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    line-height: 1;
    content: '*';
}

.page-form-item-control {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    column-gap: 10px;
}

.page-form-item-control input {
    flex: 1;
}
