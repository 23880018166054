.talent-card {
    background-color: var(--section-color);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
}

.talent-card .talent-card-main {
    display: flex;
    flex-direction: column;
}

.talent-card .talent-card-main .talent {
    display: flex;
    flex-direction: column;
}

.talent-card .talent-card-main .talent .text-container.opened {
    /* color: white; */
}

.talent-card .talent-card-main .talent .text-container.closed {
    color: grey;
}

.talent-card .talent-card-main .talent .edit-container .talent-post-btns {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 10px;
}