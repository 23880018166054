.user-menu-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; 
  opacity: 1;
  pointer-events: visible;
}

.user-menu-panel {
  z-index: 2;
  position: absolute;
  right: 0px;
  top: 50px;
  /* width: 120px; */
  background-color: var(--header-menu-color);
  border-radius: 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0px 10px 5px 10px;
  box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);
}

.user-menu-item {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  /* color: white; */
  height: 35px;
}
