.template-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 15px;
    padding-bottom: 8px;
}

.template-bar .template {
    cursor: pointer;
    color: var(--link-color);
}

.template-bar .template.active {
    color: black;
}