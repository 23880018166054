.modal-content {
    background-color: var(--page-color);
    margin: 100px auto;
    border: 1px solid #888;
    border-radius: 20px;
    /* width: 300px; */
    /* width: 50%; */
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    position: relative;
    color: black;
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    margin-top: 50px;
  }
  
  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: #f00 solid 1px; */
    width: 90%;
  }

  .item-container input {
    width: 100%;
  }

  .section-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .sendcode-container {
    /* position: relative; */
  }
  .sendcode-container .input {
    width: 70%;
  }

  .sendcode-container .countdown {
    align-self: flex-end;
  }

  .sendcode-container .sendcode-btn {
    align-self: flex-end;
    color: var(--link-color);
    cursor: pointer;
  }

  .column-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
  }
