.modal-content {
    background-color: var(--page-color);
    margin: 100px auto;
    /* padding: 7px 10px 0px 10px; */
    border: 1px solid #888;
    border-radius: 20px;
    /* width: 500px; */
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    color: black;
}

/* .interview-form {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
} */

/* .interview-form:not(.ant-form-inline):not(.ant-form-vertical) {
    max-width: 600px;
} */

.row {
    position: relative;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    zoom: 1;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.col {
    position: relative;
    min-height: 1px;
}

.form-item {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* color: rgba(0,0,0,.65); */
    /* font-size: 14px; */
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    margin-bottom: 24px;
    vertical-align: top;
}

.form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
}

/* .col-xs-24 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;

    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    float: left;

    position: relative;
    padding-right: 0;
    padding-left: 0;
} */

.form-inline .form-item>.form-item-control-wrapper,.form-inline .form-item>.form-item-label {
    display: inline-block;
    vertical-align: top
}

.form-item-control {
    position: relative;
    line-height: 40px;
    zoom:1
}

.form-item-control::before,.form-item-control::after {
    display: table;
    content: ''
}

.form-item-control::after {
    clear: both
}

.form-item-children {
    position: relative
}

.form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    /* font-size: 14px; */
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: '*'
}

/* 日期选择style覆写 */
.react-datepicker-wrapper {
    width: 100%;
}

textarea.base-input-text {
    max-width: 100%;
    height: auto;
    min-height: 40px;
    line-height: 1.5;
    vertical-align: bottom;
    -webkit-transition: all .3s,height 0s;
    transition: all .3s,height 0s;

    overflow: auto;
    resize: vertical;
}

.base-input-text {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    /* height: 32px; */
    padding: 4px 11px;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all .3s;
    transition: all .3s;
}