.main {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px 10px;
    row-gap: 10px;
}

.agreement {
    font-size: 90%;
}

.ant-card.selected {
    border: 1px solid var(--link-color);
    font-weight: bold;
}

.recharge-value span {
    font-size: 130%;
    font-weight: bolder;
    display: inline-block;
    flex: 1;
    justify-content: center;
    
    /* overflow: hidden; */
    white-space: nowrap;
    text-overflow: ellipsis;
}

.recharge-amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    line-height: 90%;

    /* overflow: hidden; */
    white-space: nowrap;
    text-overflow: ellipsis;
}

.recharge-amount .jpy {
    font-size: 110%;
}
.recharge-amount .or {
    font-size: 50%;
}
.recharge-amount .cny {
    font-size: 110%;
}
/* @media (max-width: 1068px) {
    .recharge-item {
        height: 180px;
        width: 180px;
        font-size: 100%;
    }
}

@media (max-width: 850px) {
    .recharge-item {
        height: 160px;
        width: 160px;
        font-size: 100%;
    }
}

@media (max-width: 710px) {
    .recharge-item {
        height: 150px;
        width: 150px;
        font-size: 100%;
    }
} */

@media (max-width: 450px) {
    .recharge-item {
        font-size: 75%;
    }
}