.modal-content {
  background-color: var(--page-color);
  margin: 100px auto;
  border: 1px solid #888;
  border-radius: 20px;
  /* width: 300px; */
  /* width: 50%; */
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  position: relative;
  color: black;
}

/* 容器 */
.user-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 2px 2px;
}

/* 登录方式切换容器 */
.user-box-tabs {
  width: 100%;
  padding: 32px 24px 0;
  box-sizing: border-box;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  clear: both;
}

/* 登录方式切换标题容器 */
.user-box-tabs-titles {
  font-weight: 600;
  height: 32px;
  box-sizing: content-box;
  margin-bottom: 16px;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: center;
  position: relative;
  color: black;
}

/* 登录方式切换标题 */
.user-box-tabs-titles span  {
  width: 120px;
  display: inline-block;
  font-size: 15px;
  height: 22px;
  line-height: 16px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  color: var(--link-color);
}

/* 登录方式切换标题 */
.user-box-tabs-titles span.active  {
  color: black;
}
.user-box-tabs-titles span.active::after  {
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  background: gray;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.hide{display: none;}

.show{display: block;}

.forget-btn {
  align-self: flex-end;
  color: var(--link-color);
  cursor: pointer;
}

.forget-password {
  font-size: 16px;
}