.modal-form {

}

.modal-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin-bottom: 20px;
}

.modal-form-item-label {
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
}

.required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    line-height: 1;
    content: '*';
}

.modal-form-item-control {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    column-gap: 10px;
}

.modal-form-item-control input {
    flex: 1;
}

.countdown {
    align-self: flex-end;
}

.sendcode-btn {
    align-self: flex-end;
    color: var(--link-color);
    cursor: pointer;
}