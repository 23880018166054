.interview-list-container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 10px 0px 0px 0px;
    background-color: var(--section-header-color);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    color: white;
}

.interview-list-container .content {
    /* font-size: 100%; */
    margin: 0px 0px 0px 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--section-color);
    /* column-gap: 10px; */
    padding: 10px;
}

.interview-list-container .content .interview {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.interview-list-container .content .interview.expired {
    color: darkgray;
}

.interview-list-container .content .interview .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;

    flex: 1;
    overflow: hidden;
    /* max-width: 50%; */
}

.interview-list-container .content .interview .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;

    overflow: hidden;
    /* max-width: 60%; */
}

.interview-list-container .content .interview .text {
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
}