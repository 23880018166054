.main {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px 10px;
    row-gap: 10px;
}
.topic-card {
    background-color: var(--section-color);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
}