.case-event-container {
    display: flex;
    flex-direction: column;
}

.case-event-container .title {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 10px 0px 0px 0px;
    background-color: var(--section-header-color);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    color: white;
}

/* 加载中 */
.case-event-container .case-event-loading {

}

/* 空数据 */
.case-event-container .case-event-empty {
    margin: 0px 0px 0px 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--section-color);
    column-gap: 10px;
    padding: 10px;
}

/* 列表数据 */
.case-event-container .case-event-list {
    margin: 0px 0px 0px 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--section-color);
    column-gap: 10px;
    padding: 10px;

    display: flex;
    flex-direction: column;
}

/* 营业事件-列表项目 */
.case-event-container .case-event-list .case-event-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;

    color: var(--link-color);
    cursor: pointer;
}

/* 营业事件-列表项目-事件名称 */
.case-event-container .case-event-list .case-event-list-item .name {
    margin-right: 10px;
}

/* 营业事件-列表项目-营业事件公司名称 */
.case-event-container .case-event-list .case-event-list-item .company {
    margin-right: 10px;
}

/* 营业事件-列表项目-营业事件要员名称 */
.case-event-container .case-event-list .case-event-list-item .talent {
    margin-right: 10px;
}

.case-event-container .case-event-list .case-event-list-item .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;

    flex: 1;
    overflow: hidden;
}
.case-event-container .case-event-list .case-event-list-item .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;

    overflow: hidden;
}

/* 营业事件-列表项目-事件文字描述 */
.case-event-container .case-event-list .case-event-list-item .text {
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
}